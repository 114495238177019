import React, { useContext, useState, useEffect } from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import styled, { css, ThemeProvider } from "styled-components";
import Settings from "src/stores/Settings";
import { LabelXS, HeaderS } from "src/atoms/Typography";
import Bubble from "src/atoms/Bubble";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import ChipSelector from "src/molecules/ChipSelector";
import Filter from "src/atoms/Vectors/Standard/Filter";
import Close from "src/atoms/Vectors/Standard/Close";
import { mediaquery, spacing, icons, colors } from "src/styles/variables";
import Scoop from "src/atoms/Lottie/FilterScoop";

const CONTENT_TYPES = Object.freeze({
  ContentfulInfluencerWithCard: "influencer",
  ContentfulEditorialPhotographyWithCard: "article",
  ContentfulEditorialIllustrationWithCard: "iarticle",
  ContentfulFactJustCard: "fact",
  ContentfulProductFlavorWithCard: "product",
  ContentfulRecipeWithCard: "recipe",
});

const categoryChipTheme = {
  chip: {
    bg: colors.white,
    activeBg: colors.activiaGreen,
    color: colors.darkGrey,
    activeColor: colors.white,
    border: colors.white,
    activeBorder: colors.activiaGreen,
  },
};

const contentTypeChipTheme = {
  chip: {
    bg: colors.lighterGrey,
    activeBg: colors.lighterGrey,
    color: colors.darkGrey,
    activeColor: colors.darkGrey,
    border: colors.white,
    activeBorder: colors.activiaGreen,
  },
};

const FilterDrawer = ({ categories, contentTypes, onFilterChange }) => {
  const [instructionsOpen, setInstructionsOpen] = useState(true);
  const [showBubble, setShowBubble] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasDrawerOpened, setHasDrawerOpened] = useState(false);
  const [scoopPosition, setScoopPosition] = useState(-1);
  const [scoopDirection, setScoopDirection] = useState(-1);
  const [activeFilters, setActiveFilters] = useState({
    category: [],
    contentType: [],
  });
  const [isVisible, setIsVisible] = useState(false);

  const [numActiveFilters, setNumActiveFilters] = useState(0);
  let timeout = null;

  const getSelectedFilters = (urlParams) => {
    const params = urlParams.replace("?", "");
    params.split("&").forEach((filter) => {
      const [filterType, filterValue] = filter.split("=");
      let filterValueArray = filterValue
        .split(",")
        .map((string) => decodeURI(string));
      if (filterType === "ct") {
        const v = filterValueArray.map((f) => {
          return Object.keys(CONTENT_TYPES).find((k) => CONTENT_TYPES[k] === f);
        });
        setTimeout(() => {
          updateActiveFilters(v, "contentType");
        }, 0);
      } else if (filterType === "c") {
        setTimeout(() => {
          updateActiveFilters(filterValueArray, "category");
        }, 0);
      }
    });
  };

  useEffect(() => {
    if (isVisible) {
      const { category, contentType } = activeFilters;
      const categoryFilters = category.length
        ? `c=${category.join(",")}`
        : null;
      const contentFilters = contentType.length
        ? `ct=${contentType.map((c) => CONTENT_TYPES[c]).join(",")}`
        : null;
      const urlParams = `?${categoryFilters || ""}${
        categoryFilters && contentFilters ? "&" : ""
      }${contentFilters || ""}`;
      navigate(urlParams, {
        replace: true,
        state: { keepScrollPosition: true },
      });
    }
  }, [activeFilters.category, activeFilters.contentType]);

  useEffect(() => {
    const urlParams = window.location.search;
    if (urlParams && urlParams !== "") {
      getSelectedFilters(urlParams);
    }
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, []);

  const { gridInstructionsTitle, filterDrawerTitle } = useContext(
    Settings
  ).translations;

  const sortedCategories = categories.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const sortedContentTypes = contentTypes.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const closeInstructions = () => {
    setInstructionsOpen(false);
  };

  const openDrawer = () => {
    if (instructionsOpen && timeout) {
      closeInstructions();
      clearTimeout(timeout);
      timeout = null;
    }

    if (!hasDrawerOpened) {
      setHasDrawerOpened(true);
      setTimeout(() => {
        setDrawerOpen(true);
      }, 0);
    } else {
      setDrawerOpen(true);
    }
  };

  const updateActiveFilters = (filters, type) => {
    const newFilters = { ...activeFilters, [type]: filters };
    const count = newFilters.category.length + newFilters.contentType.length;
    setActiveFilters(newFilters);
    setNumActiveFilters(count);
    onFilterChange(newFilters);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const triggerClose = () => {
    timeout = setTimeout(() => {
      closeInstructions();
      clearTimeout(timeout);
      timeout = null;
    }, 6000);
  };

  useEffect(() => {
    if (hasDrawerOpened) {
      setScoopPosition(drawerOpen ? 0 : 1);
      setScoopDirection(drawerOpen ? 1 : -1);
    }
  }, [drawerOpen]);

  useEffect(() => {
    setShowBubble(true);
    triggerClose();
    return () => {
      clearTimeout(timeout);
      timeout = null;
    };
  });

  return (
    <Wrapper>
      <ButtonWrapper drawerOpen={drawerOpen}>
        <StyledBubble isOpen={showBubble && instructionsOpen}>
          <BubbleTitle as="p">{gridInstructionsTitle}</BubbleTitle>
          <BubbleList as="p">
            {sortedCategories.map((item) => item.label).join(", ")}
          </BubbleList>
        </StyledBubble>
        <FilterButton
          size={SIZES.large}
          onClick={openDrawer}
          badge={numActiveFilters}
          instructionsOpen={showBubble && instructionsOpen}
        >
          <Filter
            size={icons.m}
            stroke={
              showBubble && instructionsOpen
                ? colors.white
                : colors.activiaGreen
            }
          />
        </FilterButton>
      </ButtonWrapper>

      <Drawer drawerOpen={drawerOpen}>
        <StyledScoop position={scoopPosition} direction={scoopDirection} />
        <DrawerContent
          drawerOpen={drawerOpen}
          hasDrawerOpened={hasDrawerOpened}
        >
          <DrawerTitle as="h2">{filterDrawerTitle}</DrawerTitle>
          <ThemeProvider theme={categoryChipTheme}>
            <ChipSelector
              items={sortedCategories}
              onChange={(filters) => updateActiveFilters(filters, "category")}
              selectedChips={activeFilters.category}
            />
          </ThemeProvider>
          <ThemeProvider theme={contentTypeChipTheme}>
            <StyledChipSelector
              items={sortedContentTypes}
              onChange={(filters) =>
                updateActiveFilters(filters, "contentType")
              }
              selectedChips={activeFilters.contentType}
            />
          </ThemeProvider>
          <CloseButton onClick={closeDrawer} size={SIZES.medium}>
            <Close size={icons.m} />
          </CloseButton>
        </DrawerContent>
      </Drawer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const ButtonWrapper = styled.div`
  opacity: ${({ drawerOpen }) => (drawerOpen ? 0 : 1)};
  transition: opacity 0.3s cubic-bezier(0.58, 0, 0.56, 1);
`;

const FilterButton = styled(FloatingButton)`
  position: fixed;
  bottom: ${spacing.default.xl};
  right: ${spacing.default.xl};
  cursor: pointer;
  background: ${({ instructionsOpen }) =>
    instructionsOpen ? colors.activiaGreen : colors.white};
  transition: background 0.3s cubic-bezier(0.58, 0, 0.56, 1);

  svg {
    transition: stroke 0.3s cubic-bezier(0.58, 0, 0.56, 1);
  }

  ${mediaquery.md(css`
    right: 50%;
    transform: translateX(50%);
  `)}
`;

const BubbleTitle = styled(LabelXS)`
  color: ${colors.mediumGrey};
  margin: ${spacing.stack.xs};
  text-align: left;
  ${mediaquery.md(css`
    text-align: center;
  `)}
`;

const BubbleList = styled(LabelXS)`
  color: ${colors.activiaGreen};
  text-align: left;
  ${mediaquery.md(css`
    text-align: center;
  `)}
`;

const StyledBubble = styled(Bubble)`
  position: fixed;
  bottom: 70px;
  right: ${spacing.default.md};
  max-width: 300px;

  ${mediaquery.md(css`
    right: 50%;
    transform: translateX(50%);

    > div {
      padding-bottom: 4rem;
    }
  `)}
`;

const Drawer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  text-align: left;
  pointer-events: ${({ drawerOpen }) => (drawerOpen ? "all" : "none")};
  transform: translateY(${({ drawerOpen }) => (drawerOpen ? 0 : "100%")});
  will-change: transform;

  transition: ${({ drawerOpen }) =>
    drawerOpen
      ? css`transform 0.5s cubic-bezier(0.58, 0, 0.56, 1)`
      : css`transform 0.6s cubic-bezier(0.58, 0, 0.56, 1)`};
`;

const DrawerContent = styled.div`
  position: relative;
  background: ${colors.lighterGrey};
  left: 0;
  bottom: 0;
  width: 100%;
  display: ${({ hasDrawerOpened }) => (hasDrawerOpened ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  padding: ${spacing.default.lg};

  ${mediaquery.md(css`
    text-align: center;
    align-items: center;
    padding: 0;
  `)}
`;

const StyledScoop = styled(Scoop)`
  margin-bottom: -${spacing.default.xs};
`;

const CloseButton = styled(FloatingButton)`
  cursor: pointer;
  box-shadow: none;
  position: absolute;
  top: ${spacing.default.xs};
  right: 10vw;
  margin: 0;

  ${mediaquery.md(css`
    position: relative;
    right: auto;
    top: auto;
    margin: ${spacing.stack.xl};
  `)}
`;

const DrawerTitle = styled(HeaderS)`
  color: ${colors.darkGreen};
  margin: ${spacing.default.xl} 0 ${spacing.default.md} 0;

  ${mediaquery.md(css`
    margin: ${spacing.stack.md};
  `)}
`;

const StyledChipSelector = styled(ChipSelector)`
  margin: ${spacing.stack.lg};
`;

const selectorShape = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })
).isRequired;

FilterDrawer.propTypes = {
  categories: selectorShape,
  contentTypes: selectorShape,
  onFilterChange: PropTypes.func.isRequired,
};

export default FilterDrawer;
